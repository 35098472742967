import React from "react"
import { Carousel } from "react-bootstrap"
import ReactPlayer from "react-player"
// import vd1 from "../../../img/carousel/loadingtop.gif"
import Axios from "axios"
import { REQUEST_URL, PATH_URL } from "../../../config"

class CarouselTop extends React.Component {
  state = {
    data: [],
    video: "",
  }

  componentDidMount = async () => {
   
    await Axios.post(REQUEST_URL + "/topcarousel/", {}).then(response => {
      if (response.data.status) {
        let type = response.data.type
        if (type === "Video") {
          
          this.setState({
            video: response.data.data[0].nameimg,
            type: type,
          })
          this.props.ChkCarousel(true, response.data.data[0].nameimg)
        } else if (type === "Carousel") {
          this.setState({
            data: response.data.data,
            video: response.data.data[0].nameimg,
            type: type,
          })
          this.props.ChkCarousel(true, response.data.data)
        }
      }
    })
  }

  render() {
    return (
      <div
        // className="container-fluid"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "10px",
        }}
      >
        {this.props.Show && this.state.type === "Video" ? (
          <ReactPlayer
            url={PATH_URL + "carousel/" + this.props.Data}
            autoPlay
            playing={true}
            loop={true}
            width="100%"
            height="100%"
            volume={0}
            muted
          />
        ) : this.props.Show && this.state.type === "Carousel" ? (
          <Carousel style={{ marginTop: "10px" }}>
            {this.props.Data.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={PATH_URL + "carousel/" + item.nameimg}
                    alt="First"
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
        ) : (
          ""
        )}

        {/* {this.state.type === "Video" ? (
          <ReactPlayer
            url={PATH_URL + "carousel/" + this.state.video}
            autoPlay
            playing={true}
            loop={true}
            width="100%"
            height="100%"
            volume={0}
            muted
          />
        ) : (
          <Carousel style={{ marginTop: "10px" }}>
            {this.state.data.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={PATH_URL + "carousel/" + item.nameimg}
                    alt="First"
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
        )} */}
      </div>
    )
  }
}

export default CarouselTop
